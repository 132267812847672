.no__highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Invisible scroll */
.scrollbar-hidden {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0px;
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.nav_bar_gradient {
  background: rgb(29,193,221);
  background: linear-gradient(92.95deg, #1DC1DD 1.44%, #4683E6 75.58%, #5F5EEC 98.99%);
}
